/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import axios from 'axios'
import dicomParser from 'dicom-parser'
import partition from 'lodash/partition'
import sumBy from 'lodash/sumBy'

import anonymizeDicom from './anonymizeDicom'
import { Errors } from './constants'

function getFormData({ signedToken, uploadId }) {
    const formData = new FormData()
    formData.append('uploadId', uploadId)
    for (const [key, value] of Object.entries<string>({ ...signedToken })) {
        if (value !== null) {
            formData.append(key, value)
        }
    }
    return formData
}
function convertFiles({ caseId, dicomReceiverUrl, signedToken, uploadId }) {
    const formData = getFormData({ signedToken, uploadId })
    try {
        axios.post(`${dicomReceiverUrl}convert_files/${caseId}/`, formData)
    } catch (err) {
        throw new Error(Errors.ConvertFilesFailed)
    }
}

async function prepareConvertingFiles({ caseId, dicomReceiverUrl, signedToken, uploadId }) {
    const formData = getFormData({ signedToken, uploadId })
    try {
        const response = await axios.post(`${dicomReceiverUrl}prepare_converting_files/${caseId}/`, formData)
        return response.data
    } catch (err) {
        throw new Error(Errors.ConvertFilesFailed)
    }
}

async function receiveProcessingMetaData({ caseId, dicomReceiverUrl, signedToken, uploadId }) {
    const formData = getFormData({ signedToken, uploadId })
    try {
        const response = await axios.post(`${dicomReceiverUrl}receive_processing_meta_data/${caseId}/`, formData)
        return response.data
    } catch (err) {
        throw new Error(Errors.ConvertFilesFailed)
    }
}

function sleep(milliseconds) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
    })
}

async function checkIfProcessingIsFinished(formData, caseId, dicomReceiverUrl) {
    try {
        const response = await axios.post(`${dicomReceiverUrl}check_if_processing_is_finished/${caseId}/`, formData)
        await sleep(3000)
        return response
    } catch (err) {
        throw new Error(Errors.ConvertFilesFailed)
    }
}

async function waitForProcessingIsFinished({ caseId, dicomReceiverUrl, signedToken, uploadId }) {
    const formData = getFormData({ signedToken, uploadId })
    let response = null
    do {
        // eslint-disable-next-line no-await-in-loop
        response = await checkIfProcessingIsFinished(formData, caseId, dicomReceiverUrl)
    } while (!response.data.processingFinished)

    return response.data
}

function renameFile({ byteArray, fileEnding, index, originalFilename }) {
    const blob = new window.Blob([byteArray])
    blob.originalFilename = originalFilename
    blob.name = `${index}${fileEnding}`
    return blob
}

function anonymizeDicomFile({ byteArray, caseId, file, index, anonymizeStudyDate = true }) {
    if (file.name.toLowerCase() === 'dicomdir') {
        throw new Error(Errors.NotASupportedImageFile)
    }
    let dataSet
    try {
        dataSet = dicomParser.parseDicom(byteArray)
    } catch (e) {
        throw new Error(Errors.NotASupportedImageFile)
    }

    try {
        anonymizeDicom(dataSet, {
            caseId,
            anonymizeStudyDate
        })
    } catch (e) {
        throw new Error(Errors.AnonymizationFailed)
    }
    return renameFile({ byteArray, fileEnding: '.dcm', index, originalFilename: file.name })
}

function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(new Uint8Array(reader.result as any))
        reader.onerror = () => reject(new Error(Errors.ReadingFileFailed))
        reader.readAsArrayBuffer(file)
    })
}

async function uploadFile({
    caseId,
    dicomReceiverUrl,
    file,
    index,
    onUploadProgress,
    signedToken,
    uploadId
}: {
    caseId?: any
    dicomReceiverUrl: any
    file: any
    index: any
    onUploadProgress?: any
    signedToken: any
    uploadId: any
}) {
    const formData = getFormData({ signedToken, uploadId })
    formData.append(index, file, file.name)
    try {
        await axios.post(`${dicomReceiverUrl}upload_files/${caseId}/`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress
        })
    } catch (error) {
        throw new Error(Errors.UploadFailed)
    }
}

const computeProgress = notIgnoredFiles => {
    if (notIgnoredFiles.length === 0) {
        return 100
    }
    const totalBytes = sumBy(notIgnoredFiles, 'size')
    const [uploadedFiles, notUploadedFiles] = partition(notIgnoredFiles, 'uploaded')
    const partiallyUploadedFiles = notUploadedFiles.filter(f => f.uploadedBytes > 0)
    const uploadedBytes = sumBy(uploadedFiles, 'size') + sumBy(partiallyUploadedFiles, 'uploadedBytes')
    return (uploadedBytes * 100) / totalBytes
}

function checkErrorMessage(err, file) {
    if (err.message === Errors.AnonymizationFailed) {
        throw new Error(interpolate(gettext('Failed to anonymize "%s"'), [file.originalFilename || file.name]))
    } else if (err.message === Errors.ReadingFileFailed) {
        throw new Error(interpolate(gettext('Failed to read "%s".'), [file.originalFilename || file.name]))
    } else if (err.message === Errors.UploadFailed) {
        const formatString = gettext('An error occurred while uploading file "%s".')
        throw new Error(interpolate(formatString, [file.originalFilename || file.name]))
    } else {
        throw new Error(
            interpolate(gettext('An unknown error occurred processing "%s".'), [file.originalFilename || file.name])
        )
    }
}

export {
    anonymizeDicomFile,
    checkErrorMessage,
    convertFiles,
    computeProgress,
    readFile,
    renameFile,
    prepareConvertingFiles,
    receiveProcessingMetaData,
    uploadFile,
    waitForProcessingIsFinished
}
